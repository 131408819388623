import React, { useState } from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Icon from "../components/icon"
import HeroBanner from "../components/HeroBanner"
import Video from "../components/video"
import { ModalProvider, BaseModalBackground } from "styled-react-modal";
import { StyledModal } from "../styledComponents/modal"
import {
  Section,
  SectionHeading,
  SectionLede,
} from "../styledComponents/section"
import { StyledButton, StyledCloseModalButton } from "../styledComponents/button"
import { HeroVideo } from "../styledComponents/heroBanner"
import { VideoWrapper } from "../styledComponents/video"
import {
  TeamSection,
  TeamList,
  TeamListItem,
  TeamPhotoWrapper,
  TeamPosition,
  TeamBiography,
} from "../styledComponents/team"


function FancyModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  return (
    <>
      <StyledButton onClick={toggleModal}>Read more</StyledButton>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        className="index-modal"
      >
        <StyledCloseModalButton onClick={toggleModal}>
          <Icon name="cross" />
        </StyledCloseModalButton>
        {parse(data.data)}
      </StyledModal>
    </>
  );
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const Team = ({ data, location }) => {
  const pageTitle = data.wpPage.title
  const lede = data.wpPage.content.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '');
  return (
    <Layout displayNavBorder>
      <SEO title={data.wpPage.title} />
      <Section>
        <Heading level={1}>
          {pageTitle}
        </Heading>
        <SectionLede>
          {parse(lede)}
        </SectionLede>
      </Section>
      <HeroBanner page="free-trial">
        <HeroVideo>
          <VideoWrapper>
            <Video videoURL="team" placeholder="/video-placeholder-no-control-bar.png" />
          </VideoWrapper> 
        </HeroVideo>
      </HeroBanner>
      <TeamSection id="our-leadership">
        <TeamList>
          {data.wpPage.OurTeam.kaarmibeingTeamList.map(
            member => {

              const memberImage = {
                imageData: member.kaarmibeingTeamPhoto.localFile.childImageSharp.gatsbyImageData,
                alt: member.kaarmibeingTeamPhoto.altText || ``, 
              }

              return (
                <TeamListItem
                  key={member.kaarmibeingTeamPhoto.id}
                >
                  <Heading level={3}>
                    {member.kaarmibeingTeamName}
                  </Heading>
                  <TeamPhotoWrapper>
                    <GatsbyImage image={memberImage.imageData} alt={memberImage.alt} />
                    <figcaption>
                      <TeamPosition>
                        {member.kaarmibeingTeamTitle}
                      </TeamPosition>
                      <TeamBiography>
                        {member.kaarmibeingTeamShortBio}
                      </TeamBiography>
                    </figcaption>
                  </TeamPhotoWrapper>
                  <ModalProvider backgroundComponent={FadingBackground}>
                      <FancyModalButton data={member.kaarmibeingTeamFullBio}/>
                  </ModalProvider>
                </TeamListItem>
              )
            }
          )}
        </TeamList>
      </TeamSection>
    </Layout>
  )
}

export default Team

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: {eq: "team"}) {
      title
      content
      OurTeam {
        kaarmibeingTeamList {
          kaarmibeingTeamFullBio
          kaarmibeingTeamName
          kaarmibeingTeamShortBio
          kaarmibeingTeamTitle
          kaarmibeingTeamPhoto {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
